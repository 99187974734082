.App {
  text-align: center;
  background-color: whitesmoke;
  height: 100%;
}

.resp-iframe-container {
  position: relative;
  padding-top: 20%;
  min-height: 600px;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#barcode {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
